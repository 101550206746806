import React, { useState, useEffect, useRef } from 'react'
import Input from 'uiKit/inputs/Input'
import SubmitButton from 'uiKit/buttons/SubmitButton'
import LoaderOverlay from 'uiKit/loaders/loaderOverlay'
import { alertError, alertSuccess } from 'api'
import { fetchReviewUrlData, updateReviewUrlData } from '../../../api/reviewUrl'

import * as S from './ReviewUrl.style'

interface Props {
  activeBot: any
}

export const ReviewUrl: React.FC<Props> = ({ activeBot }) => {
  const [url, setUrl] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const containerEndRef = useRef(null)

  const updateReviewUrl = () => {
    fetchReviewUrlData(activeBot.id)
      .then(res => setUrl(res.url || ''))
      .catch(error => alertError(error?.message || 'Fail. Try it later'))
  }

  useEffect(() => {
    updateReviewUrl()
  }, [])

  const handleSubmit = () => {
    if (!error && url) {
      setLoading(true)
      updateReviewUrlData(activeBot.id, url)
        .then(res => {
          setUrl('')
          alertSuccess('URL is submitted successfully')
          updateReviewUrl()
        })
        .catch(error => alertError(error?.message || 'Fail. Try it later'))
        .finally(() => setLoading(false))
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUrl = event.target.value
    validateUrl(newUrl)
    setUrl(newUrl)
  }

  const validateUrl = (value: string) => {
    if (!/^https?:\/\/[^ "]+$/.test(value)) {
      setError(true)
    } else {
      setError(false)
    }
  }

  return (
    <>
      <S.InputWrap>
        <Input
          error={!!error}
          value={url}
          placeholder="Enter URL for review"
          onChange={handleChange}
        />
        {error && <S.ErrorAlert>{error}</S.ErrorAlert>}
      </S.InputWrap>
      <S.ButtonWrap>
        <SubmitButton
          title="Submit URL"
          type={'primary'}
          onClick={handleSubmit}
          disabled={!!error || loading || !url}
        />
      </S.ButtonWrap>
      {loading && <LoaderOverlay />}
      <div ref={containerEndRef} />
    </>
  )
}