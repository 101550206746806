import { createJsonHeaders, logoutOnRequestOrResponseJson } from '../../../api'
import { BASE_URL } from '../../../configs'

async function updateReviewUrlData(botId, data) {
  const response = await fetch(`${BASE_URL}/bot/${botId}/review-url-config`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'PUT',
    body: data,
  })
  return await logoutOnRequestOrResponseJson(response).then(json => json.data)
}

async function fetchReviewUrlData(botId) {
  const response = await fetch(`${BASE_URL}/bot/${botId}/review-url-config`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
  return await logoutOnRequestOrResponseJson(response).then(json => json.data)
}
export { updateReviewUrlData, fetchReviewUrlData }
